import React from 'react';

import DefaultLayout from '../../components/defaultLayout/DefaultLayout';
import ChartMapTable from '../../components/ChartMapTable/ChartMapTable';

function MapsPage() {

	return (
		<>
        <div className='DirectoryPageWrapper'>
          <ChartMapTable />
        </div>

    </>
	);

}
export default MapsPage;