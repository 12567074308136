import React, { useState } from 'react';
import { Button, Form, Input, Modal, Upload, Progress } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { UploadOutlined } from '@ant-design/icons';

//* CREATE NEW ONE LOGIC

const CreateForm = (params) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [progressState, setProgressState] = useState(0);
  const userData = localStorage.getItem('userData');
  let token = null;
  if (userData) {
    token = JSON.parse(userData).token;
  }

  const CollectionCreateForm = ({ open, onCreate }) => {
    return (
      <Modal
        open={open}
        title="Загрузить файл"
        okText={uploading ? 'Загрузка...' : 'Создать'}
        cancelText="Отмена"
        onCancel={() => setOpen(false)}
        type="primary"
        // onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        onOk={() => { // onOk || onCreate
          form
            .validateFields()
            .then((values) => {
              form.resetFields();

              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
          encType="multipart/form-data"
        >
          <Form.Item
            name="filename"
            label="Имя файла"
            rules={[
              {
                required: true,
                message: 'Это поле не может быть пустым!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="path"
            rules={[
              {
                required: true,
                message: 'Это поле не может быть пустым!',
              },
            ]}>
            <Upload
              {...propsCreate}
              // type="file"
              // accept=".hgt"
              // beforeUpload={beforeUpload}
              name="path"
            >
              <Button icon={<UploadOutlined />}>Выбрать файл</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    )
  };

  const propsCreate = {
    // name: 'filehgt',
    // type: 'file',
    accept: '.hgt',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      console.log('newFileList', newFileList);
      return setFileList(...fileList, newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const onCreate = async (values) => { // -----------------------------------------
    try {
      console.log('Received values of form: ', values);
      const user_id = JSON.parse(localStorage.userData).userId;

      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('file', file);
        formData.append('raw_path', values.filename);
        formData.append('user_id', user_id);
        formData.append('target', params.type);
      });

      setUploading(true);
      setOpen(false);

      // Загрузка файла на сервер
      const response = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', 'api/app/map/upload');
        xhr.responseType = 'text';
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);

        xhr.upload.onload = async () => {
          if (xhr.status >= 400) {
            reject(xhr.response);
          } else {
            resolve(xhr.response);
            setFileList([]);
            setUploading(false);
          }
        }

        xhr.upload.onprogress = (event) => {
          setProgressState(+Math.floor((event.loaded / event.total) * 100));
          console.log(+Math.floor((event.loaded / event.total) * 100));
        }

        xhr.upload.onerror = () => {
          reject(xhr.response);
        }

        xhr.send(formData);
      });
    } catch (err) {
      console.log('ERR: onCreate >>> ', err);
    }
  };

  return (
    <>
      <div className='ChartPage__header'>
        {uploading ? <Progress
          type="circle"
          percent={progressState}
          size={80} />
        : <Button
          type='primary'
          size='small'
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setOpen(true);
          }}
        >Добавить поле</Button>}
        <CollectionCreateForm
          open={open}
          onCreate={onCreate}
          onCancel={(values) => {
            console.log('VALUES >> ', values);
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
              });
            setOpen(false);
          }}
        />
      </div>
    </>
  );
      }

export default CreateForm;