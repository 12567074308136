import { createSlice } from '@reduxjs/toolkit';

const unreadMessagesSlice = createSlice({
  name: 'unreadMessages',
  initialState: {},
  reducers: {
    incrementUnreadMessages(state, action) {
      const { chatId } = action.payload;
      if (state[chatId]) {
        state[chatId] += 1;
      } else {
        state[chatId] = 1;
      }
    },
    resetUnreadMessages(state, action) {
      const { chatId } = action.payload;
      state[chatId] = 0;
    },
  },
});

export const { incrementUnreadMessages, resetUnreadMessages } = unreadMessagesSlice.actions;

export default unreadMessagesSlice.reducer;
