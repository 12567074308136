// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usersPageWrapper {
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users/index.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,cAAc;AACf","sourcesContent":[".usersPageWrapper {\r\n\twidth: 90%;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\tmargin: 0 auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
