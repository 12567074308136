// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DirectoryPageWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.DirectoryPage__title {
  padding: 20px 0 10px 0;
  font-size: 16px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/directoryPage/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".DirectoryPageWrapper {\n  width: 90%;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n}\n\n.DirectoryPage__title {\n  padding: 20px 0 10px 0;\n  font-size: 16px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
