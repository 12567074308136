import { makeAutoObservable } from 'mobx';

class DirectoryStore {
  forwarders = null;
  agents = null;
  finalDestination = null;
  waypoint = null;
  method = null;
  filename = null;
  termsOfDelivery = null;
  port = null;
  destination = null;
  customsId = null;
  formsOfControlId = null;
  warehouseId = null;

  constructor() {
    makeAutoObservable(this);
  }

  setDirectory(data, name) {
    if (Array.isArray(data)) {
      const dataKey = data?.map((item) => {
        return { ...item, key: item.id };
      });
      this[name] = dataKey;
    }
  }

  setFilename(data) {
    this.filename = data;
  }
}

export default new DirectoryStore();
