// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContent {
	position: absolute;
	padding-left: 5%;
	margin-top: 3%;
}

.Uploader__container {
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 3%;
	width: auto;
	flex-direction: column;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChartContent/index.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,cAAc;CACd,WAAW;CACX,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":[".mainContent {\r\n\tposition: absolute;\r\n\tpadding-left: 5%;\r\n\tmargin-top: 3%;\r\n}\r\n\r\n.Uploader__container {\r\n\tpadding-left: 5%;\r\n\tpadding-right: 5%;\r\n\tmargin-top: 3%;\r\n\twidth: auto;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
