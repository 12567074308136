import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { increment } from '../store/counterSlice';

export const useHttp = () => {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const userData = localStorage.getItem('userData');
  // let token = null;
  // if (userData) {
  //   token = JSON.parse(userData).token;
  //   console.log('token >>>', token);
  // }

  // let headers = { Authorization: `Bearer ${token}` };

  const request = useCallback(
    async (
      url,
      method = 'GET',
      body = null
      // headers = { Authorization: `Bearer ${token}` }
    ) => {
      const userData = localStorage.getItem('userData');
      let token = null;
      if (userData) {
        token = JSON.parse(userData).token;
      }
      let headers = { Authorization: token};
      setLoading(true);
      let data = null;
      try {
        if (body) {
          body = JSON.stringify(body);
          headers['Content-Type'] = 'application/json';
        }

        const response = await fetch(url, {
          method,
          body,
          headers,
        });

        data = response.status === 204 ? [] : await response.json();

        if (data.code === 401 && userData) {
          const refToken = JSON.parse(userData).refToken;
          const userId = JSON.parse(userData).userId;
          const role = JSON.parse(userData).role;

          try {
            const respData = await request('/api/auth/refresh-tokens', 'POST', {
              refreshToken: refToken,
            });

            localStorage.setItem(
              'userData',
              JSON.stringify({
                token: respData.tokens.access.token,
                refToken: respData.tokens.refresh.token,
                userId,
              })
            );
            // window.location.reload();
            /* --- повторный запрос при смене токена --- */
            let newResponse = null;

            if (method !== 'GET') {
              newResponse = await fetch(url, {
                method,
                headers: {
                  Authorization: `Bearer ${respData.tokens.access.token}`,
                  'Content-Type': 'application/json',
                },
                body,
              });
            } else {
              newResponse = await fetch(url, {
                method,
                headers: {
                  Authorization: `Bearer ${respData.tokens.access.token}`,
                },
              });
            }

            data =
              (await newResponse.status) === 204
                ? []
                : await newResponse.json();
            /* --- END повторный запрос при смене токена --- */
          } catch (error) {
            console.log('ERROR !!! >>>', error);
          }
        }

        // if (!response.ok && data.code !== 403) {
        //   throw new Error(data.message || 'Что-то пошло не так');
        // }

        setLoading(false);
        return data;
      } catch (e) {
        setError(e.message);
        setLoading(false);
        throw e;
      }
    },
    []
  );

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError, setLoading };
};
