import React, {useState, useEffect} from "react";
import { Spin, Typography, Space } from 'antd';
import { useHttp } from '../../hooks/http.hook';

const { Text, Title } = Typography;

export default () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { request } = useHttp();

  useEffect(() => {
    effectFunc()
  }, [])

  const effectFunc = async () => {
    await getDataFromStatistic()
    toggleIsLoading()
  }

  const getDataFromStatistic = async () => {
    const response = await request('api/statistics/info')
    setData(response)
  }

  const toggleIsLoading = () => setIsLoading(prevIsLoading => !prevIsLoading)

  return <div style={{margin: '10px'}}>
    <Spin spinning={isLoading}>
      <Space direction={'vertical'}>
        <Title level={2}>Статистика</Title>
        <Text type="success">Пультов: {data ? data.panel : 0}</Text>
        <Text type="success">Планшетов: {data ? data.tablet : 0}</Text>
        <Text type="success">Онлайн: {data ? data.online : 0}</Text>
        Статистика содержит только те устройства, которые не были помечены как удаленные.
        Тут ниже будет график с выбором дат
      </Space>
    </Spin>
  </div>
}