import { Button, Result } from 'antd';

const ErrorFallback = () => {
	return (
		<div>
		<Result
			status="500"
			title="500"
			subTitle="Похоже, что-то пошло не так 😔"
			// extra={<Button type="primary">Назад</Button>}
		/>
		</div>
	)
}

export {ErrorFallback}
