import React, { useState, useEffect } from 'react';
import { Button, Modal, } from 'antd';

const FeatureModal = (params) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="default" onClick={showModal} size='small' style={{ width: '100%', position: 'relative' }}>
        Свойства
      </Button>
      <Modal title={`Информация о пользователе`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <b>Позывной:</b>
        <p>{`${params.name ? params.name : 'Отсутствует'}`}</p>
        {!params.flag ? <b>Логин:</b> : null}
        {!params.flag ? <p>{`${params.email ? params.email : 'Отсутствует'}`}</p> : null}
        <b>Телефон/Телеграм:</b>
        <p>{`${params.tel ? params.tel : 'Отсутствует'}`}</p>
        <b>Ответственный:</b>
        <p>{`${params.sub_id_name ? params.sub_id_name : 'Отсутствует'}`}</p>
      </Modal>
    </>
  );
}

export default FeatureModal;