import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
	name: 'preloader',
	initialState: {
		isFetching: false,
	},
	reducers: {
		increment: (state, action) => {
			state.isFetching = state.isFetching ? false : true;
		},
	}
});

export const { increment } = counterSlice.actions;

export default counterSlice.reducer;