// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GeoDataPageWrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/geoDataPage/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,cAAc;AAClB","sourcesContent":[".GeoDataPageWrapper {\n    width: 90%;\n    display: flex;\n    flex-direction: column;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
