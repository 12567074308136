import {useState, useEffect, useRef} from 'react';
import { on, removeAllListeners } from '../../socket';
import ACTIONS from '../../utils/socketActions';
import {useNavigate} from 'react-router-dom';
import { Badge, Button, Card, List } from 'antd';
import {v4} from 'uuid';
import { useHttp } from '../../hooks/http.hook';

export default function MainCallPage() {
  const { request } = useHttp();
  const history = useNavigate();
  const [rooms, updateRooms] = useState([]);
  const rootNode = useRef();

  useEffect(() => {
    on(ACTIONS.SHARE_ROOMS, async ({rooms = []} = {}) => {
      if (rootNode.current) {
        const response  = await request('api/calls/webrtc_getClientRooms')
        updateRooms(response.rooms);
      }
    });

    return () => {
      removeAllListeners(ACTIONS.SHARE_ROOMS);
    }
  }, []);

  return (
    <div ref={rootNode}>
      <Button onClick={() => {
        const v4gen = v4();
        history(`/room/${v4gen}`);
      }}>Создать новый звонок
      </Button>
      <h1>Доступные комнаты</h1>
      <List grid={{gutter: 16, column: 4}}
            dataSource={rooms}
            renderItem={(item) => (
              <List.Item>
                <Badge count={item.subscribersCount}>
                <Card title={`${item.initiator}`} key={item.roomID}>
                  <Button onClick={() => {
                    history(`/room/${item.roomID}`); // Добавь по сокету проверку на присоединение к группе
                  }}>Присоединиться
                  </Button>
                </Card>
                </Badge>
              </List.Item>
            )} />
    </div>
  );
}