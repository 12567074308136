import React from 'react';
import ChatComponent from "../../components/ChatComponent/ChatComponent";


const MessagesPage = () => {

  return (
    <><ChatComponent /></>
  )
}

export default MessagesPage;