const ACTIONS = {
  JOIN: 'webrtc_join',
  LEAVE: 'webrtc_leave',
  SHARE_ROOMS: 'webrtc_share-rooms',
  ADD_PEER: 'webrtc_add-peer',
  REMOVE_PEER: 'webrtc_remove-peer',
  RELAY_SDP: 'webrtc_relay-sdp',
  RELAY_ICE: 'webrtc_relay-ice',
  ICE_CANDIDATE: 'webrtc_ice-candidate',
  SESSION_DESCRIPTION: 'webrtc_session-description',
  TOGGLE_VIDEO: 'webrtc_toggle-video',
};

module.exports = ACTIONS;