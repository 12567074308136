import React, { useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { Input, Popconfirm, Button } from 'antd';

const SearchField = (params) => {
  const [inputValue, setInputValue] = useState('');
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const {
    setValue,
    setTotalData,
    setData,
    setSearcher,
    fetchList,
    actualSegment,
    searchValue,
    setSearchValue,
    setGenerateIdOpen,
    searchRef,
  } = params;
  const { request } = useHttp();

  const onSearch = async (value) => {
    if (value.length) {
      setValue(value);
      /**
       * actualSegment используется для корректного поиска по устройствам в разделе Список устройств
       * Для раздела Офлайн устройств параметр передаваться не будет
       */
      const response = await request(actualSegment ? `/api/device/find_with/${value}?actualSegment=${actualSegment}`:
      `/api/device_offline/find_with/${value}`);

      if (response.error) {
        setIsConfirmVisible(true)
        setSearchValue(value)
      } else {
        setIsConfirmVisible(false)
        setTotalData(response.count)
        setData(response.rows.map((el, i) => ({ ...el, key: i + 1 })));
        setSearcher(true);
      }
    } else {
      setSearcher(false);
      fetchList(actualSegment);
    }
  }

  const handleConfirm = () => {
    setIsConfirmVisible(false)
    setSearchValue(inputValue)
    setInputValue('')
    setGenerateIdOpen(true)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      onSearch(inputValue);
    }
  };

  return (
    <>
      <Input
        placeholder="Поиск"
        allowClear
        style={{ marginBlock: 10, width: '45%', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}
        value={inputValue}
        ref={searchRef}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
      >
      </Input>
      <Popconfirm
        title="Устройство не найдено!"
        description={`Хотите создать устройство с ID ${inputValue}?`}
        open={isConfirmVisible && inputValue===searchValue}
        onConfirm={() => handleConfirm()}
        onCancel={() => {
          setSearchValue('')
          setInputValue('')
          setIsConfirmVisible(false)
        }}
        okText="Продолжить"
        cancelText="Отмена">
        <Button type='primary' style={{borderTopLeftRadius: '0', borderBottomLeftRadius: '0', margin: '10px 2px 10px 0'}} onClick={() => onSearch(inputValue)}>Найти</Button>
      </Popconfirm>
    </>
  )
}

export default SearchField;