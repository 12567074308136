// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GeoData__header {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /*text-align: right;*/
}`, "",{"version":3,"sources":["webpack://./src/components/GeoData/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".GeoData__header {\n    padding-bottom: 20px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: row;\n    /*text-align: right;*/\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
