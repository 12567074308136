import React, {useEffect, useState} from 'react';
import Uploader from '../../components/uploader/Uploader';
import './index.css';
import {useHttp} from "../../hooks/http.hook";
import {Skeleton} from "antd";

const ChartContent = () => {

	const [actualVersions, setActualVersions] = useState([]);
	const [loading, setLoading] = useState(false);
	const { request } = useHttp();
	const availibleResultSelection = [
		{urlResult: 'eye', text: 'Eye', index:'0' },
		{urlResult: 'droneVision', text: 'DroneVision', index:'1' },
		{urlResult: 'eye4SDK', text: 'Глаз (4 SDK)', index:'2' },
		{urlResult: 'eye3', text: 'Глаз 3', index:'3' },
		{urlResult: 'eyeLite', text: 'Глаз Lite', index:'4' },
		{urlResult: 'droneDetector', text: 'Drone Detector', index:'5' },
		{urlResult: 'berry', text: 'Berry', index:'6' },
	]

	const fetchActualVersions = async () => {
		try {
			setLoading(true);
			request('api/app/getActualVersions')
				.then((resData) => {
					const { eye, droneVision, eye4SDK, eye3, eyeLite, droneDetector, berry } = resData;
					setActualVersions([eye, droneVision, eye4SDK, eye3, eyeLite, droneDetector, berry]);
				})
				.finally(() => setLoading(false))
		} catch (err) {
			console.log('error in fetchActualVersions ', err);
		}
	}

	useEffect(() => {
		fetchActualVersions();
	}, []);

	return (
		<>
		{availibleResultSelection.map((item) => (loading ? <div className="Uploader__container"><Skeleton paragraph={{rows: 2}} active/></div> : <Uploader
			urlResult={item.urlResult}
			text={item.text}
			index={item.index}
			actualVersions={actualVersions} 
			fetchActualVersions={fetchActualVersions}
			/>))}
		</>
	)
}
export default ChartContent;