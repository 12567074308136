import React from 'react';

function MainPage() {
  return (
    <>
      
    </>
  );
}

export default MainPage;
