import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import UserStore from './store/UserStore';
import { Provider } from 'react-redux';
import store from './store';
import './globalStyle.css';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';

export const Context = createContext(null);

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={(e) => { console.log(e, 'error') }}>
  <Provider store={store}>
    <Context.Provider
      value={{
        user: new UserStore(),
      }}
    >
      <BrowserRouter><App /></BrowserRouter>
    </Context.Provider>
    </Provider>
  </ErrorBoundary>
);
