// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileButton {
    position: absolute;
    right: 0%;
    top: -2%;
    height: 64px;
    right: 0;
    text-align: right;
}

.profileClick {
    width: 150px;
    height: 100%;
    top: 1%
}`, "",{"version":3,"sources":["webpack://./src/components/DrawerProfile/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,YAAY;IACZ,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ;AACJ","sourcesContent":[".profileButton {\n    position: absolute;\n    right: 0%;\n    top: -2%;\n    height: 64px;\n    right: 0;\n    text-align: right;\n}\n\n.profileClick {\n    width: 150px;\n    height: 100%;\n    top: 1%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
