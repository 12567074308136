// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Uploader__container {
  padding-left: 5%;
  padding-right: 5%;
	margin-top: 3%;
  width: auto;
  flex-direction: column;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/uploader/index.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;CAClB,cAAc;EACb,WAAW;EACX,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".Uploader__container {\n  padding-left: 5%;\n  padding-right: 5%;\n\tmargin-top: 3%;\n  width: auto;\n  flex-direction: column;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
