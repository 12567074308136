import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Table, Form, Modal, Space } from 'antd';
import getFiltersInColumn from '../../utils/filteredColumns';
import { useHttp } from '../../hooks/http.hook';
import { isMobile } from 'react-device-detect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import './index.css';

const RegRequestsTable = () => {
  const [form] = Form.useForm();
  const [updatedData, setUpdatedData] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { request } = useHttp();
  const user = useSelector(state => state.userInfo);
  const { userState } = user
  dayjs.extend(utc);

  const currentDate = dayjs.utc().minute(0).second(0).millisecond(0)
  const nextYearDate = dayjs(currentDate).add(180, 'days')

  const columns = isMobile ?
    [
      {
        title: 'Название',
        dataIndex: 'namespace',
        key: 'namespace',
        render: (text, record) => {
          return (
            <Space direction='vertical'>
              {record.deviceId}
              {record.call_sign}
              {new Date(record.updatedAt).toLocaleString().slice(0, -3)}
            </Space>
          )
        }
      },
      {
        title: 'Действия',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
          return (
            <div className='RegRequestsTable__fields'>
              <Space direction='vertical'>
                <LocalizedModalOk id={record.id} />
                <FeatureModal id={record.id}
                device_id={record.deviceId}
                password={record.password}
                call_sign={record.call_sign}
                army_post={record.army_post}
                type={record.type}
                tg={record.tg} 
                date={new Date(record.updatedAt).toLocaleString().slice(0, -3)}/>
                <LocalizedModalCancel id={record.id} />
              </Space>
            </div>
          )
        }
      }
    ] : [
      {
        title: 'ID Устройства',
        dataIndex: 'deviceId',
        key: 'deviceId',
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'deviceId'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.id) {
            return !record.id;
          } else {
            return record.id?.includes(value);
          }
        },
        sorter: (a, b) => a.deviceId?.localeCompare(b.deviceId),
        render: (text, record) => {
          return record.deviceId;
        },
      },
      {
        title: 'Логин',
        dataIndex: 'login',
        key: 'login',
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'login'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.id) {
            return !record.id;
          } else {
            return record.id?.includes(value);
          }
        },
        sorter: (a, b) => a.login?.localeCompare(b.login),
        render: (text, record) => {
          return record.login;
        },
      },
      {
        title: 'Военная часть',
        dataIndex: 'army_post',
        key: 'army_post',
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'army_post'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.id) {
            return !record.id;
          } else {
            return record.id?.includes(value);
          }
        },
        sorter: (a, b) => a.army_post?.localeCompare(b.army_post),
        render: (text, record) => {
          return record.army_post;
        },
      },
      {
        title: 'Позывной',
        dataIndex: 'call_sign',
        key: 'call_sign',
        width: 150,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) =>
          getFiltersInColumn(
            data,
            setSelectedKeys,
            selectedKeys,
            confirm,
            'call_sign'
          ),
        onFilter: (value, record) => {
          if (value === 'нет данных' && !record.id) {
            return !record.id;
          } else {
            return record.id?.includes(value);
          }
        },
        sorter: (a, b) => a.call_sign?.localeCompare(b.call_sign),
        render: (text, record) => {
          return record.call_sign;
        },
      },
      {
        title: 'Тип устройства',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        render: (text, record) => record.type === 'tablet' ? 'Планшет' : 'Пульт',
      },
      {
        title: 'Телеграм',
        dataIndex: 'tg',
        key: 'tg',
        width: 150,
        render: (text) => <a href={`https://t.me/${text}`} target={'_blank'}>{text}</a>,
      },
      {
        title: 'Дата',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 150,
        render: (text, record) => {
          let curDate = new Date(record.updatedAt).toLocaleString().slice(0, -3);
          return curDate;
        }
      },
      {
        title: 'Действия',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div style={{textAlign: 'center'}}>
              <Space direction='vertical'>
                <LocalizedModalOk id={record.deviceId} />
                <LocalizedModalCancel id={record.deviceId} />
              </Space>
            </div>
          )
        },
      }
    ];

  const LocalizedModalOk = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toApprove(params.id);

      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" onClick={showModal} size='small' style={{width: '100%', position: 'relative'}}>
          Подтвердить
        </Button>
        <Modal title="Подтвердите действие"
          open={isModalOpen}
          onOk={handleOk}
          closable={false}
          maskClosable={false}
          onCancel={handleCancel}>
          <p>Вы уверены что хотите подтвердить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const FeatureModal = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="default" onClick={showModal} size='small' style={{width: '100%', position: 'relative'}} disabled={params.device_id ? false : true}>
          Свойства
        </Button>
        <Modal title={`Информация о запросе`} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <b>Android id:</b>
          <p>{`${params.device_id ? params.device_id : 'Отсутствует'}`}</p>
          <b>Позывной:</b>
          <p>{`${params.call_sign ? params.call_sign : 'Отсутствует'}`}</p>
          <b>Военная часть:</b>
          <p>{`${params.army_post ? params.army_post : 'Отсутствует'}`}</p>
          <b>Тип устройства:</b>
          <p>{`${params.type ? params.type : 'Отсутствует'}`}</p>
          <b>Телеграм:</b>
          <p>{`${params.tg ? params.tg : 'Отсутствует'}`}</p>
          <b>Дата:</b>
          <p>{`${params.date ? params.date : 'Отсутствует'}`}</p>
        </Modal>
      </>
    );
  }

  const LocalizedModalCancel = (params) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };
    const handleOk = () => {
      toCancelled(params.id);
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    return (
      <>
        <Button type="primary" onClick={showModal} size='small' style={{width: '100%', position: 'relative'}} danger>
          Отменить
        </Button>
        <Modal title="Подтвердите действие" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <p>Вы уверены что хотите отклонить эту заявку?</p>
        </Modal>
      </>
    );
  };

  const toApprove = async (id) => {
    try {
      const license_expires = nextYearDate
      await request('/api/registered/approved', 'POST', {
        id,
        user_id: userState.userId,
        license_expires: license_expires
      });

      fetchLostDataRegRequests();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  const toCancelled = async (id) => {
    try {
      await request('/api/registered/cancelled', 'POST', {
        id: id
      });
      fetchLostDataRegRequests();
    } catch (err) {
      console.log('ERR: ', err);
    }
  }

  const fetchLostDataRegRequests = async () => {
    try {
      setLoading(true);
      request(`/api/registered/device_registered`)
        .then((resData) => {
          setData(resData.map((el, i) => ({ ...el, key: i + 1 })));
        })
        .finally(() => setLoading(false))
    } catch (error) {
      console.log('error-fetchDataForwarder >>>', error);
    }
  };

  const onFinish = async (values) => {
    try {
      await fetchLostDataRegRequests();
      form.resetFields();
    } catch (error) {
      console.log('error-getDeleteCell >>>', error, error.message);
    }
  };

  useEffect(() => {
    fetchLostDataRegRequests();
  }, []);

  useEffect(() => {
    if (updatedData) {
      const newData = data?.map((item) => {
        if (item.id === updatedData.id) {
          return { ...item, [updatedData.nameKey]: updatedData.values };
        } else {
          return item;
        }
      });
      setData(newData);
      setUpdatedData(null);
    }
  }, [updatedData]);

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ marginTop: '4%' }}
          scroll={isMobile ? null : {
            x: 2500,
          }}
          bordered
        />
      </Form>
    </>
  );

}

export default RegRequestsTable;
