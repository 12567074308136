import './index.css';
import React from 'react';

import Uploader from '../../components/uploader/Uploader';
import ChartMapTable from '../../components/ChartMapTable/ChartMapTable';
import ChartContent from '../../components/ChartContent/ChartContent';

function ChartPage() {


  return (
    <>
          <ChartContent />
    </>
  );
}
export default ChartPage;
