// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import '~antd/dist/antd.css'; */

.ChartPage__header {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	padding-right: 20px;
  }

.ChartPage__fields {
	display: flex;
	justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ChartMapTable/index.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;CACC,aAAa;CACb,yBAAyB;CACzB,oBAAoB;CACpB,mBAAmB;EAClB;;AAEF;CACC,aAAa;CACb,uBAAuB;AACxB","sourcesContent":["/* @import '~antd/dist/antd.css'; */\n\n.ChartPage__header {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tpadding-bottom: 20px;\n\tpadding-right: 20px;\n  }\n\n.ChartPage__fields {\n\tdisplay: flex;\n\tjustify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
