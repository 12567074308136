import React, { useState } from 'react';
import {
    Button,
    message,
    Modal,
} from 'antd';
import debounce from 'lodash/debounce';
import {
    EditOutlined,
} from '@ant-design/icons'
import { useHttp } from '../../hooks/http.hook';

const CurrentUserModal = ({ id, fetchUserList, fetchListDevices, DebounceSelect, actualSegment }) => {
    const { request } = useHttp();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = async () => {
        setIsModalOpen(false);
        try {
            if (value.label && value.key) {
                const response = await request('/api/device/changeUser', 'POST', {
                    userInfo: value,
                    deviceId: id,
                });

                if (!response.error) {
                    message.success('Пользователь успешно добавлен');
                } else {
                    message.warning(response.message)
                }

                fetchListDevices(actualSegment);
            } else {
                message.warning('Вы не указали пользователя ☹️');
            }
        } catch (error) {
            console.log('Validate Failed:', error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="dashed" style={{ position: 'absolute', right: '1px', top: '0px' }} onClick={showModal}>
                <EditOutlined />
            </Button>
            <Modal title="Изменение пользователя"
                   open={isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}>
                <p>Выберите пользователя для этого устройства.</p>
                <DebounceSelect
                    value={value}
                    placeholder="Выберите пользователя"
                    fetchOptions={fetchUserList}
                    onChange={(newValue) => {
                        setValue(newValue)
                    }}
                    style={{
                        width: '100%',
                    }}
                />
            </Modal>

        </>
    );
};

export default CurrentUserModal;