// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import '~antd/dist/antd.css'; */

.ChartPage__header {
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	padding-right: 20px;
  }

  .DirectoryPageWrapper {
	width: 90%;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
  }
  .collection-create-form_last-form-item {
	margin-bottom: 0;
  }`, "",{"version":3,"sources":["webpack://./src/pages/chartPage/index.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;CACC,aAAa;CACb,yBAAyB;CACzB,oBAAoB;CACpB,mBAAmB;EAClB;;EAEA;CACD,UAAU;CACV,aAAa;CACb,sBAAsB;CACtB,cAAc;EACb;EACA;CACD,gBAAgB;EACf","sourcesContent":["/* @import '~antd/dist/antd.css'; */\n\n.ChartPage__header {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tpadding-bottom: 20px;\n\tpadding-right: 20px;\n  }\n\n  .DirectoryPageWrapper {\n\twidth: 90%;\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin: 0 auto;\n  }\n  .collection-create-form_last-form-item {\n\tmargin-bottom: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
